export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SET_PRODUCT_PAGE_STATE = "SET_PRODUCT_PAGE_STATE"

export const setProductDetails = (product_details) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_DETAILS,
    payload: product_details,
  });
};

export const setAllProduct = (all_products) => (dispatch) => {
  dispatch({
    type: SET_ALL_PRODUCTS,
    payload: all_products
  });
};

export const setProductPage = (pageState) => (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PAGE_STATE,
    payload: pageState
  });
};
