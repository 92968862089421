import "./home_testimonials.css";
import React, { useState, useEffect } from 'react';
import CarouselSlider from "../ImageSlider/carousel.component";
import customer1 from "../../Assets/customer1.svg";
import customer2 from "../../Assets/customer2.svg";
import customer3 from "../../Assets/customer3.svg";
// import customer4 from "../../Assets/customer4.svg";
import customer5 from "../../Assets/customer5.svg";
import customer6 from "../../Assets/customer6.svg";
import customer7 from "../../Assets/customer7.svg";
// import customer8 from "../../Assets/customer8.svg";
import customer9 from "../../Assets/customer9.svg";
import customer10 from "../../Assets/customer10.svg";
import customer11 from "../../Assets/customer11.svg";
import customer12 from "../../Assets/customer12.svg";
import customer13 from "../../Assets/customer13.svg";
import customer14 from "../../Assets/customer14.svg";
import customer15 from "../../Assets/customer15.svg";
import customer16 from "../../Assets/customer16.svg";
import customer17 from "../../Assets/customer17.svg";
import customer18 from "../../Assets/customer18.svg";
import customer19 from "../../Assets/customer19.svg";
// import customer20 from "../../Assets/customer20.svg";
import customer21 from "../../Assets/customer21.svg";


const TestimonialsCarousel = () => {
  const [ismobile, setIsmobile] = useState(false)
  useEffect(() => {
    if (window.screen.width <= 760) {
        setIsmobile(true)
    }
}, [])

  const testimonials = {
    "testimonials": [
      {
        "image": customer1
      },
      {
        "image": customer2
      },
      {
        "image": customer3
      },
      // {
      //   "image": customer4
      // },
      {
        "image": customer5
      },
      {
        "image": customer6
      },
      {
        "image": customer7
      },
      // {
      //   "image": customer8
      // },
      {
        "image": customer9
      },
      {
        "image": customer10
      },
      {
        "image": customer11
      },
      {
        "image": customer12
      },
      {
        "image": customer13
      },
      {
        "image": customer14
      },
      {
        "image": customer15
      },
      {
        "image": customer16
      },
      {
        "image": customer17
      },
      {
        "image": customer18
      },
      {
        "image": customer19
      },
      // {
      //   "image": customer20
      // },
      {
        "image": customer21
      },
    ]
  }
  return (
    <div className="testimonials-carousel-main-container">
      <div className="testimonials-carousel-title">Our Customers</div>
      {
        !ismobile ? 
        <div className="testimonials-description">
          At our company, we are committed to providing our customers with high-quality, effective, and eco-friendly biodegradable products. We use only the best materials and production processes to create products that are both safe for you and the environment. Thank you for choosing to make a positive impact on the environment with our biodegradable products.      </div>
        :
        <div className="testimonials-description">
          At our company, we are committed to providing our customers with high-quality, effective, and eco-friendly biodegradable products. We use only the best materials and production processes to create products that are both safe for you and the environment. Thank you for choosing to make a positive impact on the environment with our biodegradable products.      </div>
      }
      
      <CarouselSlider 
      
        type={0}
        data={testimonials.testimonials}
        autoPlay={true}
        slidesToSlide={0.25}
        transition="all 1s linear"
      />
    </div>
  );
};

export default TestimonialsCarousel;
