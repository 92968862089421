import "./footer.component.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import TtyIcon from '@mui/icons-material/Tty';
import FooterLogo from '../../Assets/footer-logo.svg';
import Footer1 from '../../Assets/Footer1.png';
import Footer2 from '../../Assets/Footer2.svg';
import Footer3 from '../../Assets/Footer3.svg';
import Footer4 from '../../Assets/Footer4.png';
// import Foot from '../../Assets/Gallery/whatsappimage1.svg';
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
import { store } from "../../";
import { setProductPage } from '../../Redux/actions/product.action';

const Footer = () => {
  const navigate = useNavigate();

  const start = Date.now();
  // Your operation
  const totalTimeTaken = Date.now() - start;
  console.log(totalTimeTaken);

  const routeToHome = () => {
    navigate(siteMap.HomePage.path, { replace: false });
  }

  // const routeToProducts = () => {
  //   store.dispatch(setProductPage(0))
  //   navigate(siteMap.ProductPage.path, { replace: false });
  // }

  const routeToGallery = () => {
    navigate(siteMap.GalleryPage.path, { replace: false });
  }

  const routeToCertification = () => {
    navigate(siteMap.CertificationPage.path, { replace: false });
  }

  const routeToAbout = () => {
    navigate(siteMap.AboutPage.path, { replace: false });
  }

  const routeToContact = () => {
    localStorage.setItem("contactOption", 0);
    navigate(siteMap.ContactPage.path, { replace: false });
  }

  const routeToTerms = () => {
    navigate(siteMap.TermsAndConditionPage.path, { replace: false });
  }

  const handleCups = (id) => {
    store.dispatch(setProductPage(0))
    localStorage.setItem("preCategoryId", id)
    navigate(siteMap.CategoryPage.path, { state : {id:id}}, { replace: true });
  }


  return (
    <footer className="footer-main-container">
      {/* <div className="footer-container-1">
        <div className="footer-container-1-title">
          Get connected with us on social media
        </div>

      </div> */}
      <div className="footer-container-2">
        <div className="footer-container-2-left">
          <div className="footer-container-2-left-warp">
            <div className="footer-container-2-left-title">
              <img className="footer-image" src={FooterLogo} alt="" />
            </div>
            <div className="footer-container-2-left-content">
              <div className="footer-container-1-social-container">
                <div
                  className="item"
                  onClick={() => {
                    window.open("https://twitter.com/ZedByte");
                  }}
                >
                  <TwitterIcon
                    style={{
                      color: "white",
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  className="item"
                  onClick={() => {
                    window.open("https://www.instagram.com/zedbytecorp/");
                  }}
                >
                  <InstagramIcon
                    style={{
                      color: "white",
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  className="item"
                  onClick={() => {
                    window.open("https://wa.me/+918825464712");
                  }}
                >
                  <WhatsAppIcon
                    style={{
                      color: "white",
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div
                  className="item"
                  onClick={() => {
                    window.open("tel:+918825464712");
                  }}
                >
                  <CallIcon
                    style={{
                      color: "white",
                      width: 20,
                      height: 20,
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="footer-container-2-left-content">
              <div className="footer-container-1-social-container">
                <div
                  className="item"
                >
                  <img style={{
                    color: "black",
                    width: 60,
                    height: 60,
                    cursor: "pointer",
                  }} src={Footer1} alt="" />
                </div>
                <div
                  className="item"
                >
                  <img style={{
                    color: "black",
                    width: 90,
                    height: 90,
                    cursor: "pointer",
                  }} src={Footer2} alt="" />
                </div>
                <div
                  className="item"
                >
                  <img style={{
                    color: "black",
                    width: 90,
                    height: 90,
                    cursor: "pointer",
                  }} src={Footer3} alt="" />
                </div>
                <div
                  className="item"
                >
                  <img style={{
                    color: "black",
                    width: 50,
                    height: 50,
                    cursor: "pointer",
                  }} src={Footer4} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="footer-container-2-right">
          <div className="footer-container-2-right-warp">

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Quick Links</div>
              <div className="footer-container-2-right-content" onClick={() => { routeToHome() }}>Home</div>
              {/* <div className="footer-container-2-right-content" onClick={() => { routeToProducts() }}>Products</div> */}
              <div className="footer-container-2-right-content" onClick={() => { routeToGallery() }}>Gallery</div>
              <div className="footer-container-2-right-content" onClick={() => { routeToCertification() }}>
                Certification
              </div>
              <div className="footer-container-2-right-content" onClick={() => { routeToAbout() }}>About Us</div>
              <div className="footer-container-2-right-content" onClick={() => { routeToContact() }}>Contact Us</div>
              <div className="footer-container-2-right-content" onClick={() => { routeToTerms() }}>Terms And Conditions</div>
            </div>

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Our Products</div>
              <div className="footer-container-2-right-content" onClick={()=>handleCups(1)}>CUPS</div>
              <div className="footer-container-2-right-content" onClick={()=>handleCups(2)}>FOOD PACKAGING</div>
              <div className="footer-container-2-right-content" onClick={()=>handleCups(3)}>MEAT PACKAGING</div>
            </div>

            <div className="footer-container-2-right-column">
              <div className="footer-container-2-right-title">Contact Info</div>
              <div className="footer-container-2-right-content">
                <LocationOnIcon
                  style={{
                    color: "white",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                />
                No.60/47, 1 st Main Road, CIT Nagar, Chennai, Tamil Nadu - 600035, India.              </div>
              <div className="footer-container-2-right-content">
                <CallIcon
                  style={{
                    color: "white",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                />
                +91 9502952951
              </div>
              <div className="footer-container-2-right-content">
                <TtyIcon
                  style={{
                    color: "white",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                />
                044 - 35008540 <br />
                044 - 35008541
              </div>
              <div className="footer-container-2-right-content">
                <EmailIcon
                  style={{
                    color: "white",
                    width: 20,
                    height: 20,
                    cursor: "pointer",
                  }}
                />
                sales@leafedindia.com
              </div>
            </div>



          </div>
        </div>
      </div>
      <div className="footer-container-4">
        <h1 className="footer-container-4-content">
          All Copyrights Reserved @ 2023
        </h1>
        <h1 className="footer-container-3-content">
          Designed and developed by <span style={{ paddingLeft:"5px", cursor: "pointer" }} onClick={() => {
            window.open("https://www.bitsuptech.com")
          }}>Bitsup Technologies</span>
        </h1>
        {/* <h1 className="footer-container-4-content">
          <span style={{ cursor: "pointer" }} onClick={() => {
            routeToTerms()
          }}>Terms And Conditions</span>
        </h1> */}
      
      </div>
      {/* <div className="footer-container-3">
        <h1 className="footer-container-3-content">
          Designed and developed by Bitsup Technologies
        </h1>
      </div> */}
    </footer>
  );
};
export default Footer;
