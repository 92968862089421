// production config

export const config = {
  server_url: process.env.REACT_APP_PRODUCTION_SERVER_URL
}

// development config

// export const config = {
//   server_url: process.env.REACT_APP_DEVELOPEMENT_SERVER_URL
// };
