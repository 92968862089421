import { SET_PRODUCT_DETAILS } from "../../actions/product.action";


const updateProductDetails = (state=null, action) => {
    switch(action.type){
        case SET_PRODUCT_DETAILS:
            return {user_details: action.payload}
        default:
            return state
    }
}


export default updateProductDetails