import "./Contact.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { SendMail, SendMailWithImage } from "../../Services/networkCall";
import { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
const Contact = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [category, setCategory] = useState(null);
  const option = localStorage.getItem("contactOption");
  const ContactSEO = useSelector((state) => state.allseo);
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);
  const handleChange = (event) => {
    setCategory(event.target.value);
    // alert(event.target.value);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      phone: Yup.number().min(10, "Must be 10 characters").required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if(category===null){
        alert("Please Select Category")
      }
      else{
      values["photos"] = selectedImage;
      parseInt(option) === 0
        ? SendMail(values.name, values.phone, values.email, values.message)
        : SendMailWithImage(
            selectedImage,
            values.name,
            values.phone,
            values.email,
            values.message
          );
      alert("enquired successfully!");
        }
        
    },
  });


  return (
    <div className="contact-main-container">
      <Helmet>
        <title>
          {ContactSEO === null ? "Contact" : ContactSEO.result[6].title}
        </title>
        <meta
          name="description"
          content={
            ContactSEO === null ? "Contact" : ContactSEO.result[6].description
          }
        />
        <meta
          name="keywords"
          content={
            ContactSEO === null ? "Contact" : ContactSEO.result[6].keywords
          }
        />
      </Helmet>
      <div className="contact-title">Contact Us</div>
      <div className="contact-section-1-container">
        <div className="contact-left-container">
          <form className="form-container" onSubmit={formik.handleSubmit}>
            <div className="contact-form-input-warp">
              <label className="label" htmlFor="name">
                Name
              </label>
              <input
                className="form-input"
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="contact-error">{formik.errors.name}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp">
              <label className="label" htmlFor="phone">
                Phone
              </label>
              <input
                className="form-input"
                id="phone"
                name="phone"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="contact-error">{formik.errors.phone}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp">
              <label className="label" htmlFor="email">
                Email Address
              </label>
              <input
                className="form-input"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="contact-error">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp">
              <label className="label">Category</label>
              <Select
                className="form-input"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleChange}
              >
                <MenuItem value={"Cups"}>Cups</MenuItem>
                <MenuItem value={"Food Packaging"}>Food Packaging</MenuItem>
                <MenuItem value={"Meat Packaging"}>Meat Packaging</MenuItem>
              </Select>
              {formik.touched.email && formik.errors.email ? (
                <div className="contact-error">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="contact-form-input-warp">
              <label className="label" htmlFor="message">
                Message
              </label>
              <textarea
                className="form-text"
                id="message"
                name="message"
                type="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="contact-error">{formik.errors.message}</div>
              ) : null}
            </div>

            {parseInt(option) === 0 ? (
              <div></div>
            ) : (
              <>
                <label
                  htmlFor="filePicker"
                  className="contact-submit"
                  // onClick={(event) => {
                  //   setSelectedImage(event.target.files[0]);
                  // }}
                >
                  Upload Design
                </label>
                <input
                  id="filePicker"
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                  }}
                  style={{ visibility: "hidden" }}
                  // onChange={(event) => {
                  //   setSelectedImage(event.target.files[0]);
                  // }}
                />
              </>
            )}
            <button className="buttonStyle" type="submit">
              Enquiry
            </button>
            {/* <p>
              Do not be hesitant to contact us; we are eager to assist you
            </p> */}
          </form>
        </div>
        <div className="contact-right-container">
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.128336905795!2d80.230773!3d13.0274984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267a47c13211d%3A0x2e9bf2df90d97227!2sLeafedindia!5e0!3m2!1sen!2sin!4v1677575689161!5m2!1sen!2sin"
            className="google-map"
            display="block"
            position="relative"
          />
        </div>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.128336905795!2d80.230773!3d13.0274984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267a47c13211d%3A0x2e9bf2df90d97227!2sLeafedindia!5e0!3m2!1sen!2sin!4v1677575689161!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.394481370634!2d80.21671641403336!3d13.137498914772719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265cfad484251%3A0xca12e783c5fc4f61!2sstartup%20booster!5e0!3m2!1sen!2sin!4v1674718433483!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.394564053124!2d80.21671641438715!3d13.13749369074719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265cfad484251%3A0xca12e783c5fc4f61!2sstartup%20booster!5e0!3m2!1sen!2sin!4v1674551014223!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </div>
    </div>
  );
};
export default Contact;
