import { SET_ALL_SEO } from "../../actions/seo.action"

const allseo = (state=null, action) => {
    switch(action.type){
        case SET_ALL_SEO:
            return action.payload
        default:
            return state
    }
}


export default allseo