import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';


const Type3 = ({typeThreeDetail}) => {
  const [orientation, setOrientation] = useState("vertical")
  const detailsKeys = Object.keys(typeThreeDetail || {})
  useEffect(() => {
    console.log("FROM TYPE 2 DETAILS", typeThreeDetail);
    if (window.screen.width <= 720) {
      setOrientation("horizontal");
      // setGap("3vh");
    }
  }, [])
  return (
    <>
      <div className="Details-Container">
        <div className="Details-title">
          Specification
        </div>
        <div className="Product-Details">
          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[0]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail.size}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[1]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail.board}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[2]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail.material}
            </div>
          </div>
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
        </div>


        <div className="Product-Details">
          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[6]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail["MOQ Generic"]}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[5]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail["MOQ Customization"]}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[4]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail.GSM}
            </div>
          </div>
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
        </div>

        <div className="Product-Details-Last">
          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[3]}
            </div>
            <div className="detail-box-value">
              {typeThreeDetail.printing}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Type3