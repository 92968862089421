import { SET_ALL_GALLERY } from "../../actions/gallery.action"

const allgallery = (state=[], action) => {
    switch(action.type){
        case SET_ALL_GALLERY:
            return action.payload.result
        default:
            return state
    }
}


export default allgallery