import "./header.component.css"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import LOGO from '../../Assets/logo.png'
import { store } from "../../";
import { setProductPage } from '../../Redux/actions/product.action';


export const Dropdown = ({title, className}) => {
  const [ismobile, setIsmobile] = useState(false)
  useEffect(() => {
      if (window.screen.width <= 760) {
          setIsmobile(true)
      }
  }, [])
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCups = (id) => {
    store.dispatch(setProductPage(0))
    localStorage.setItem("preCategoryId", id)
    navigate(siteMap.CategoryPage.path, { state : {id:id}}, { replace: true });
    setAnchorEl(null);
  }

  const style = {
    color:"#2d4010", 
    fontWeight:"750", 
    fontFamily:"poppins", 
    fontSize:"1vw", 
    textTransform:"none"
  }

  const style1 = {
    color:"#2d4010", 
    fontWeight:"450", 
    fontFamily:"poppins", 
    fontSize:"3vw", 
    textTransform:"none"
  }


  return (
    <div>
      <Button
        style={!ismobile?style:style1}
        // className="header-menu-item"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={className} onClick={()=>handleCups(1)}>CUPS</MenuItem>
        <MenuItem className={className} onClick={()=>handleCups(2)}>FOOD PACKAGING</MenuItem>
        <MenuItem className={className} onClick={()=>handleCups(3)}>MEAT PACKAGING</MenuItem>
      </Menu>
    </div>
  );
}

const Header = () => {
  const [ismobile, setIsmobile] = useState(false)
  useEffect(() => {
      if (window.screen.width <= 760) {
          setIsmobile(true)
      }
  }, [])
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const style = {
    color:"#2d4010", 
    fontWeight:"750", 
    fontFamily:"poppins", 
    fontSize:"1vw", 
    textTransform:"none"
  }

  const style1 = {
    color:"#2d4010", 
    fontWeight:"450", 
    fontFamily:"poppins", 
    fontSize:"3vw", 
    textTransform:"none"
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const routeToHome = () => {
    navigate(siteMap.HomePage.path, { replace: false });
    handleClose()
  }


  const routeToGallery = () => {
    navigate(siteMap.GalleryPage.path, { replace: false });
    handleClose()
  }

  const routeToCertification = () => {
    navigate(siteMap.CertificationPage.path, { replace: false });
    handleClose()
  }

  const routeToAbout = () => {
    navigate(siteMap.AboutPage.path, { replace: false });
    handleClose()
  }

  const routeToContact = () => {
    localStorage.setItem("contactOption", 0);
    navigate(siteMap.ContactPage.path, { replace: false });
    handleClose()
  }

  const start = window.performance.now();
  // Your operation
  const totalTimeTaken = window.performance.now() - start;
  console.log(totalTimeTaken);

  return (
    <header className="header-main-container">
      <div className="header-logo-container" onClick={() => { routeToHome() }}>
        <img className="header-logo" src={LOGO} alt="" />
      </div>
      <div className="header-menu-container">
        <div className="desktop-menu">
          <MenuItem style={!ismobile?style:style1} onClick={() => { routeToHome() }}>Home</MenuItem>
          <Dropdown title="Products"/>
          {/* <MenuItem className="header-menu-item" onClick={() => { routeToProducts() }}>Products</MenuItem> */}
          <MenuItem style={!ismobile?style:style1} onClick={() => { routeToGallery() }}>Gallery</MenuItem>
          <MenuItem style={!ismobile?style:style1} onClick={() => { routeToCertification() }}>Certification</MenuItem>
          <MenuItem style={!ismobile?style:style1} onClick={() => { routeToAbout() }}>About Us</MenuItem>
          <MenuItem style={!ismobile?style:style1} onClick={() => { routeToContact() }}>Contact Us</MenuItem>
        </div>
        <div className="header-mobile-menu">
          <div onClick={handleClick}>
            <MenuIcon />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem style={!ismobile?style:style1} onClick={() => { routeToHome() }}>Home</MenuItem>
            <Dropdown title="Products" style={{fontSize: "3vw"}}/>
            {/* <MenuItem className="header-menu-item" onClick={() => { routeToProducts() }}>Products</MenuItem> */}
            <MenuItem style={!ismobile?style:style1} onClick={() => { routeToGallery() }}>Gallery</MenuItem>
            <MenuItem style={!ismobile?style:style1} onClick={() => { routeToCertification() }}>Certification</MenuItem>
            <MenuItem style={!ismobile?style:style1} onClick={() => { routeToAbout() }}>About Us</MenuItem>
            <MenuItem style={!ismobile?style:style1} onClick={() => { routeToContact() }}>Contact Us</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  )
}
export default Header