import { SET_ALL_PRODUCTS } from "../../actions/product.action";


const allProducts = (state=null, action) => {
    switch(action.type){
        case SET_ALL_PRODUCTS:
            return action.payload.result
        default:
            return state
    }
}


export default allProducts