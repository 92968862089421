import './About.css'
// import aboutBanner from '../../Assets/about-banner.png'
// import aboutBanner from '../../Assets/home1.png'
import aboutBanner from "../../Assets/CAROUSEL 3.jpg";
import ContentTemplate1 from '../../Components/ContentTemplate1/ContentTemplate1'
import about1 from '../../Assets/about1.svg'
import about2 from '../../Assets/about2.svg'
import about3 from '../../Assets/about3.svg'
import about4 from '../../Assets/about4.svg'
import about5 from '../../Assets/about5.svg'
import cert1 from '../../Assets/CIPET.png'
import cert2 from '../../Assets/ISO-1.png'
import cert3 from '../../Assets/TNPCB.png'
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useEffect } from 'react';


const About = () => {
    const AboutSEO = useSelector(state => state.allseo)
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }, [])
    const Aboutcontent = [
        {
            title: "Our Company",
            description: "LeafedIndia is a leader in the production of environmentally-friendly, compostable paper cups. We are committed to reducing waste and promoting sustainable practices by providing customers with high-quality, eco-friendly products that are designed to minimize their impact on the environment.",
            image: about1
        },
        {
            title: "Our Mission",
            description: "Our mission is to create a better future for our planet by producing innovative, sustainable solutions that reduce waste and protect the environment. We are dedicated to using the most advanced manufacturing techniques and materials to produce products that are not only functional but also environmentally responsible.",
            image: about2
        },
        {
            title: "Our Vision",
            description: "Our vision is to create a better future for our planet by producing innovative, sustainable solutions that reduce waste and protect the environment. We are dedicated to using the most advanced manufacturing techniques and materials to produce products that are not only functional but also environmentally responsible.",
            image: about3
        },
        {
            title: "What we do",
            description: "We believe in being transparent about our products, processes, and business practices. That's why we are always happy to answer any questions you may have about our composable paper cups and the impact they have on the environment.",
            image: about4
        },
        {
            title: "We value transparency",
            description: "We believe in being transparent about our products, processes, and business practices. That's why we are always happy to answer any questions you may have about our composable paper cups and the impact they have on the environment.",
            image: about5
        }
    ]

    return (
        <div className='about-main-container'>
            <Helmet>
                <title>{AboutSEO === null ? "About" : AboutSEO.result[5].title}</title>
                <meta name="description" content={AboutSEO === null ? "About" : AboutSEO.result[5].description} />
                <meta name="keywords" content={AboutSEO === null ? "About" : AboutSEO.result[5].keywords} />
            </Helmet>
            <div className="about-title">
                About Us
            </div>
            <div className="about-description">
                Our team is made up of highly skilled and experienced professionals who are committed to delivering the highest quality products and services to our customers.
            </div>
            {/* <div className="about-banner-container">
                <img className="about-banner" src={aboutBanner} alt="about banner" />
            </div> */}
            <ContentTemplate1 title={Aboutcontent[0].title} description={Aboutcontent[0].description} image={Aboutcontent[0].image} isReverse={false} isDark={true}/>
            <ContentTemplate1 title={Aboutcontent[1].title} description={Aboutcontent[1].description} image={Aboutcontent[1].image} isReverse={true} />
            <ContentTemplate1 title={Aboutcontent[2].title} description={Aboutcontent[2].description} image={Aboutcontent[2].image} isReverse={false} isDark={true}/>
            <ContentTemplate1 title={Aboutcontent[3].title} description={Aboutcontent[3].description} image={Aboutcontent[3].image} isReverse={true} />
            <ContentTemplate1 title={Aboutcontent[4].title} description={Aboutcontent[4].description} image={Aboutcontent[4].image} isReverse={false} isDark={true}/>
            {/* <div className="about-section-2-container">
            </div> */}

            <div className="about-certificates-container">
                <div className="about-certificate-title">
                    Certificates
                </div>
                <div className="about-certificates">
                    <div className="image-warp">
                        <img src={cert1} alt="" />
                    </div>
                    <div className="image-warp">
                        <img src={cert2} alt="" />
                    </div>
                    <div className="image-warp">
                        <img src={cert3} alt="" />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default About