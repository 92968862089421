export const siteMap = {
    HomePage: {
      title: "Home",
      path: "/",
      description: "Leafed's home page",
    },
    CategoryPage: {
      title: "Category",
      path: "/category",
      description: "Leafed's category page",
    },
    ProductPage: {
      title: "Product",
      path: "/product",
      description: "Leafed's product page",
    },
    ProductDetailPage: {
      title: "ProductDetail",
      path: "/product-details",
      description: "Leafed's product-detail page",
    },
    GalleryPage: {
      title: "Gallery",
      path: "/gallery",
      description: "Leafed's gallery page",
    },
    CertificationPage: {
      title: "Certification",
      path: "/certification",
      description: "Leafed's certification page",
    },
    AboutPage: {
      title: "About",
      path: "/about",
      description: "Leafed's About page",
    },
    ContactPage: {
      title: "Contact",
      path: "/contact",
      description: "Leafed's Contact page",
    },
    TermsAndConditionPage: {
      title: "Terms",
      path: "/terms",
      description: "Leafed's TermsAndCondition page",
    },
    AdminPage: {
      title: "Admin",
      path: "/admin",
      description: "Leafed's Admin page",
    }
  };
  