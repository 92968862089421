import { SET_ALL_CATEGORY } from "../../actions/category.action";


const allcategory = (state=null, action) => {
    switch(action.type){
        case SET_ALL_CATEGORY:
            return action.payload.result
        default:
            return state
    }
}


export default allcategory