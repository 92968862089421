import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';


const Type2 = ({ typeTwoDetail }) => {
  const [orientation, setOrientation] = useState("vertical")
  const detailsKeys = Object.keys(typeTwoDetail || {})
  useEffect(() => {
    console.log("FROM TYPE 2 DETAILS", typeTwoDetail);
    if (window.screen.width <= 720) {
      setOrientation("horizontal");
      // setGap("3vh");
    }
  }, [])

  const transformString = (input) => {
    const output = input.replace(/(?:^|_)([a-z]+)(?:_|$)/g, (match, p1) => p1.charAt(0).toUpperCase() + p1.slice(1) + ' ');
    return output;
  };

  return (
    <>
      <div className="Details-Container">
        <div className="Details-title">
          Specification
        </div>
        <div className="Product-Details">
          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[0]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail.size}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[1]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail.board}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[2]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail.material}
            </div>
          </div>
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
        </div>


        <div className="Product-Details">

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[5]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail.GSM}
              
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[3]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail["MOQ Generic"]}
            </div>
          </div>

          <Divider orientation={orientation} variant="middle" flexItem />

          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[4]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail["MOQ Customization"]}
            </div>
          </div>
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
        </div>

        <div className="Product-Details-Last">
          
          <div className="detail-box-1">
            <div className="detail-box-title">
              {detailsKeys[6]}
            </div>
            <div className="detail-box-value">
              {typeTwoDetail.printing}
              
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}
export default Type2