import updateProductDetails from "./Products/product_details";
import allProducts from "./Products/all_product";
import allgallery from "./Gallery/all_gallery";
import allseo from "./SEO/all_seo";
import allcategory from "./Category/all_category";
import productState from "./Products/product_page";
import { combineReducers } from "redux";


const allReducers = combineReducers({
  product_details: updateProductDetails,
  all_products: allProducts,
  allgallery: allgallery,
  allseo: allseo,
  allcategory:allcategory,
  productState
});

export default allReducers;
