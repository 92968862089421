import { SET_PRODUCT_PAGE_STATE } from "../../actions/product.action";


const productState = (state=0, action) => {
    switch(action.type){
        case SET_PRODUCT_PAGE_STATE:
            return action.payload
        default:
            return state
    }
}


export default productState