import React, { useState, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ProductZoom = ({ productImage, title }) => {
    const [width, setwidth] = useState("30%")
    const [height, setheight] = useState("40%")
    const [titleSize, setTitleSize] = useState("1vw")
    

    useEffect(() => {
        if (window.screen.width <= 760) {
            setwidth("80%")
            setheight("70%")
            setTitleSize("3vw")
        }
    }, [])


    return (
        <div style={{
            height: height,
            width: width,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
            // backgroundColor:"#000"
        }}>
            <Zoom>
                <img style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover"
                }} src={productImage} alt="" />
            </Zoom>
            <div style={{
                fontFamily: "Poppins",
                fontSize: titleSize,
                fontWeight: "700",
                fontStyle: "normal"
            }}>{title}</div>
        </div>
    )
}
export default ProductZoom;