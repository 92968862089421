import './ProductDetails.css'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { useEffect, useState } from 'react';
// import productImage from '../../Assets/glass.png'
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { siteMap } from "../../Routes/SiteMap";
import { useNavigate, useLocation } from "react-router-dom";
import downloadPdf from '../../Assets/a.pdf'
import Feature1 from '../../Assets/Feature1.svg';
import Feature2 from '../../Assets/Feature2.svg';
import Feature3 from '../../Assets/Feature3.svg';
import Feature4 from '../../Assets/Feature4.svg';
import Feature5 from '../../Assets/Feature5.svg';
// import { GetCategoryProductsData } from '../../Services/networkCall';
import { GetProductsData } from '../../Services/networkCall';
import DetailType from './DetailType';


const ProductDetails = () => {
    const ProductDetailsSEO = useSelector(state => state.allseo)
    const NetworkCategory = JSON.parse(localStorage.getItem("networkCategory"))
    // const [Products, setProducts] = useState(NetworkProducts)
    const [details, setDetails] = useState(null)
    const navigate = useNavigate();
    const { state } = useLocation();
    const { fromCategory, result } = state

    const featureImageMap = {
        1: Feature1,
        2: Feature2,
        3: Feature3,
        4: Feature4,
        5: Feature5
    }


    useEffect(() => {
        // console.log("PreCategoryId =>", PreCategoryId);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (fromCategory) {
            setDetails(result)
        } else {
            (async () => {
                const product_id = localStorage.getItem("product_id");
                const category_id = localStorage.getItem("category_id");
                console.log(product_id, category_id);
                const response = await GetProductsData(product_id, category_id);
                console.log("result from details", response.result[0]);
                setDetails(response.result[0]);
            })();
        }
    }, [fromCategory, result])


    return (
        (details !== null) ?
            <div className='ProductsDetails-Main-Container'>
                <Helmet>
                    <title>{ProductDetailsSEO === null ? "ProductDetails" : ProductDetailsSEO.result[2].title}</title>
                    <meta name="description" content={ProductDetailsSEO === null ? "ProductDetails" : ProductDetailsSEO.result[2].description} />
                    <meta name="keywords" content={ProductDetailsSEO === null ? "ProductDetails" : ProductDetailsSEO.result[2].keywords} />
                </Helmet>
                <div className="ProductDetails-Container">
                    <div className="ProductsDetails-left-container">
                        <ImageGallery
                            thumbnailPosition="left"
                            useBrowserFullscreen={false}
                            disableSwipe={true}
                            disableThumbnailSwipe={true}
                            showPlayButton={false}
                            autoPlay={true}
                            items={details?.product_images || []}
                            showNav={false}
                            showFullscreenButton={false}
                            infinite={false}
                        />
                    </div>
                    <div className="ProductDetails-right-container">
                        <div className='ProductDetails-title'>
                            {details?.category_name}
                        </div>
                        <div className='ProductDetails-title-2'>
                            {details?.product_name}
                        </div>
                        <div className="ProductDetails-description">
                            {details?.product_description}
                        </div>
                        <div className='ProductDetails-Button-Container'>

                            <div className="buttonStyle" onClick={() =>
                                navigate(siteMap.ContactPage.path, { replace: false })

                            }>
                                Enquire Now
                            </div>
                            {/* <div > */}
                            <a className="buttonStyle"
                                download={`${details?.product_name}.pdf`}
                                href={downloadPdf}>
                                Download
                            </a>
                            {/* </div> */}
                            <div className="buttonStyle" onClick={() => {
                                localStorage.setItem("contactOption", 1);
                                navigate(siteMap.ContactPage.path, { replace: false })
                            }}>
                                Upload Sample
                            </div>
                        </div>
                        <div className="ProductDetails-notes">
                            {details?.notes}
                        </div>
                    </div>
                </div>
                <div className="ProductDetails-Section-2">


                    <div className="ProductDetails-Section-2-left">
                        {/* {PreCategoryId} */}
                        <DetailType details={details}/>
                        <div className="Details-Container">
                            <div className="Details-title">
                                Features
                            </div>
                            <div className="Product-Details-feature">
                                {[1, 2, 3, 4, 5].map((key, value) => {
                                    return (<div className="feature-warp">
                                        <img className="feature-img" src={featureImageMap[value + 1]} alt={value} />
                                        {/* <div>{featureTitleMap[value + 1]}</div> */}
                                    </div>)
                                })
                                }
                            </div>
                        </div>

                        <div className="Details-Container">
                            <div className="Details-title">
                                Other Products
                            </div>
                            <div className="Product-Details-category-2">
                                {
                                    NetworkCategory.result.slice(0, 5).map((category, i) => {
                                        return (
                                            <div className="category-warp" onClick={() => {
                                                localStorage.setItem("preCategoryId", category.pre_category_id)
                                                navigate(siteMap.CategoryPage.path, { state : {id:parseInt(category.pre_category_id)}}, { replace: true });
                                            }}>
                                                <img className="img" src={category.category_image} alt="" />
                                                <div>{category.category_name}</div>
                                                {/* <div>{i}</div> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                            {/* <div>
                                -More Products 
                            </div> */}
                    </div>

                    {/* <div className="ProductDetails-Section-2-right">
                    </div> */}

                </div>
            </div> : <div></div>
    )
}
export default ProductDetails