import Type1 from "./Type1";
import Type2 from "./Type2";
import Type3 from "./Type3";


const DetailType = ({details}) => {
  const PreCategoryId = localStorage.getItem("preCategoryId") // 1 = CUPS ; 2 = FOD PACKAGING ; 3 = MEAT PACKAGING

  const SetDetailsType = ({details}) => {
    console.log("details from index", details);
    console.log("PreCategoryId", PreCategoryId);
    if(parseInt(PreCategoryId) === 1){
      // Type 1 is Cup
      return <Type1 typeOneDetail={details.cup_properties} productName={details.product_name}></Type1> 
    }else if(parseInt(PreCategoryId) === 2){
      // Type 2 is Packaging
      return <Type2 typeTwoDetail={details.package_properties}></Type2>
    }else if(parseInt(PreCategoryId) === 3){
      // Type 3 is Meat Box
      return <Type3 typeThreeDetail={details.meat_box_properties}></Type3>
    }else{
      return <Type1 typeOneDetail={details.cup_properties}></Type1>
    }
  }

  return <SetDetailsType details={details}/>
}

export default DetailType