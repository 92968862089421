import './Certificate.css'
import ProductZoom from "../../Components/MagicZoom/MagicZoom"
import withPLACOATED from '../../Assets/QC.jpg'
// import HorizontalDropdown from '../../Components/HorizontalDropdown/customHorizontalDropdown'
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useEffect } from 'react';


const Certificate = () => {

    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }, [])

      
    const CertificateSEO = useSelector(state => state.allseo)
    return (
        <div className='certification-main-container'>
            <Helmet>
                <title>{CertificateSEO === null ? "Certificate" : CertificateSEO.result[4].title}</title>
                <meta name="description" content={CertificateSEO === null ? "Certificate" : CertificateSEO.result[4].description} />
                <meta name="keywords" content={CertificateSEO === null ? "Certificate" : CertificateSEO.result[4].keywords} />
            </Helmet>
            <div className="certificate-title">
                Certificates
            </div>
            <div className="certificate-container">
                <ProductZoom productImage={withPLACOATED} title="QUALITY CERTIFICATE"/>
                {/* <ProductZoom productImage={withPLACOATED}/> */}
                {/* <HorizontalDropdown/> */}
            </div>
        </div>
    )
}
export default Certificate