import "./project.component.css";

const ProjectBox = ({ image, title, description }) => {
  return (
    <div className="project-box-container">
      <img draggable={false} className="project-image" src={image} alt="" />
      <div className="project-text-container">
        {/* <h1 className="project-title">{title}</h1> */}
        {/* <p className="project-description">{description}</p> */}
      </div>
    </div>
  );
};
export default ProjectBox;
