import "./Home.css";
import ContentTemplate1 from '../../Components/ContentTemplate1/ContentTemplate1'
// import ContentTemplate2 from '../../Components/ContentTemplate2/ContentTemplate2'
import DiscreteSlider from '../../Components/DiscretedSlider/DiscreteSlider'
import withoutPLACOATED from '../../Assets/home1.svg'
import withPLACOATED from '../../Assets/home2.svg'
import home3 from '../../Assets/home3.svg'
import CustomCarosel from "../../Components/Carousel/carosel";
import OurProduct from "../../Components/OurProduct/OurProduct";
import InfoGraphic from "../../Components/InfoGraphic/InfoGraphic";
import Services from "../../Components/Services/services";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useEffect } from "react";


const Home = () => {
  const HomeSEO = useSelector(state => state.allseo)

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [])


  const Homecontent = [
    {
      title: "Tremandous growth in food chain industries.",
      description: "In recent years, the food chain industry has experienced tremendous growth, driven by factors such as increasing population, rising incomes, and changing dietary preferences. This growth has led to the emergence of new players, innovative products, and technological advancements, shaping the future of food.",
      image: home3
    },
    {
      title: "Replacement of single useage plastic",
      description: "The replacement of single-use plastics has become a pressing environmental issue, as plastic waste pollutes our oceans and harms wildlife. Governments, companies, and individuals are taking action, with alternatives such as biodegradable plastics, paper, and reusable containers gaining popularity, driving a shift towards a more sustainable future",
      image: withoutPLACOATED
    },
    {
      title: "Biodegradable Paper Products are the solution to this.",
      description: "It is made of environmentally friendly, food-safe paper boards with a biodegradable coating. It is important to understand the value of biodegradable products in daily life.",
      image: withPLACOATED
    }
  ]

  return (
    <div className="home-main-container">
      <Helmet>
        <title>{HomeSEO === null ? "Home" : HomeSEO.result[0].title}</title>
        <meta name="description" content={HomeSEO === null ? "Home" : HomeSEO.result[0].description} />
        <meta name="keywords" content={HomeSEO === null ? "Home" : HomeSEO.result[0].keywords} />
      </Helmet>
      {/* <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="a"><path fill="currentColor" d="M756 628q-52 128-190 158t-322.5-22Q59 712 75.5 508t187-252.5q170.5-48.5 313-39T763 363q45 137-7 265Z"/></clipPath><pattern id="b" patternUnits="userSpaceOnUse" width="25" height="25" viewBox="0 0 100 100" fill="#8ed754"><circle cx="50" cy="50" r="12.5"/></pattern></defs><g clip-path="url(#a)"><path fill="url(#b)" d="M756 628q-52 128-190 158t-322.5-22Q59 712 75.5 508t187-252.5q170.5-48.5 313-39T763 363q45 137-7 265Z"/></g></svg> */}
      <CustomCarosel />
      <ContentTemplate1 title={Homecontent[0].title} description={Homecontent[0].description} image={Homecontent[0].image} isReverse={false} />
      <ContentTemplate1 title={Homecontent[1].title} description={Homecontent[1].description} image={Homecontent[1].image} isReverse={true} />
      <ContentTemplate1 title={Homecontent[2].title} description={Homecontent[2].description} image={Homecontent[2].image} isReverse={false} />
      {/* <ContentTemplate1 title={Homecontent[3].title} description={Homecontent[3].description} image={Homecontent[3].image} isReverse={true} /> */}
      <InfoGraphic />
      <OurProduct />
      {/* <DiscreteSlider /> */}
      <Services />
    </div>
  )
}
export default Home