import React, { useState, useEffect } from 'react';
import "./services.css"
import TestimonialsCarousel from "../Testimonials/home_testimonials";
import Footer from '../Footer/footer.component'

const Services = () => {
    const [ismobile, setIsmobile] = useState(false)
    useEffect(() => {
        if (window.screen.width <= 760) {
            setIsmobile(true)
        }
    }, [])
    return (
        // <section className="services-main-container">
        <div className="scroll-main-container">
            <div className="services-heading-section">
                <div className="services-container-section">
                    <h1 className="service-heading">Highlights of <br />our Innovative Products</h1>
                    <p className="service-content">
                        By switching to biodegradable packaging products, the food industry can leverage this massive source of waste and re-direct it to compost, cultivating nutrient-rich soil to support farming to grow more food. Instead of damaging the environment, biodegradable food packaging can become part of a vital circular system that both eliminates waste and boosts food production. We Leafed India do this revolution as a major role to this environment.
                    </p>
                </div>
            </div>

            <section className="cd-fixed-background img-1" data-type="slider-item">
                <div className="cd-content">
                    <h2>Biodegradable Cups</h2>
                    <ul>
                        <li>
                            Light Weight bio-degradable Products.
                        </li>
                        <li>
                            Can be offered with FSC® certification.
                        </li>
                        <li>
                            100% Food Grade paperboard with barrier coating.
                        </li>
                        <li>
                            Oil And Water resistant properties.
                        </li>
                        <li>
                            Heat sealable in existing heat sealing machines.

                        </li>
                        <li>
                            100% recyclable in existing standard recycling systems.
                        </li>
                    </ul>
                    {/* <button className="button-style">Get a quote</button> */}
                </div>
            </section>

            <section className="cd-fixed-background img-2" data-type="slider-item">
                <div className="cd-content light-background">
                    <h2>Biodegradable Takeway</h2>
                    <ul>
                        <li>
                            The growing food service & delivery market as a replacement to single-use plastics.
                        </li>
                        <li>
                            Its offers exceptional water, oil & grease resistance.
                        </li>
                        <li>
                            Its compatible with cold and hot melt glue and is ultrasonic sealable.
                        </li>
                        <li>
                            Withstands the heat of takeaway food.
                        </li>
                        <li>
                            Designed for water, oil & grease resistance.
                        </li>
                        <li>
                            Can be offered with FSC® certification.
                        </li>
                    </ul>
                    {/* <button className="button-style">Get a quote</button> */}
                </div>
            </section>

            <section className="cd-fixed-background img-3" data-type="slider-item">
                <div className="cd-content">
                    <h2>Biodegradable Tableware</h2>
                    <ul>
                        <li>
                            Single-use plastic substitute for food serving applications like plates & trays
                        </li>
                        <li>
                            100% food-grade paperboard with barrier coating.
                        </li>
                        <li>
                            Compostable under industrial composting conditions, as certified by CIPET.
                        </li>
                        <li>
                            Superior print reproduction allows for enhanced branding.
                        </li>
                        <li>
                            100% recyclable in existing standard recycling systems as certified by CPPRI.
                        </li>
                        <li>
                            Can be offered with FSC® Certification.
                        </li>
                    </ul>
                </div>
            </section>

            {/* <section className="cd-fixed-background img-4" data-type="slider-item">
                <div className="cd-content">
                    <h2>Adhesives</h2>
                    <p>
                        Some cups are made from multiple layers of paper, which are held together using adhesives. These adhesives are typically made from synthetic polymers.                    </p>
                </div>
            </section> */}

            {/* <section className="cd-fixed-background img-5" data-type="slider-item">
                <div className="cd-content">
                    <h2>Chemicals</h2>
                    <p>
                        In the process of manufacturing Biodegradable paper cups, various chemicals are used such as bleaching agents, sizing agents, and fillers.                    </p>
                </div>
            </section> */}


            <TestimonialsCarousel />
            <Footer />



        </div>
    )
}
export default Services