import './InfoGraphic.css'
import info from "../../Assets/info2.png"


const InfoGraphic = () => {
  return (
    <div className='InfoGraphic-Main-container'>
        <div className="InfoGraphic-Title">
            Features Of Biodegradable Products
        </div>
        <div className="InfoGraphic-Image_Container">
          <img className="InfoGraphicImage" src={info} alt="" />
        </div>
    </div>
  )
}
export default InfoGraphic