import './ProductCard.css'
// import productImage from '../../Assets/glass.png'
import { useNavigate } from "react-router-dom";
import { siteMap } from "../../Routes/SiteMap";
import { store } from "../../";
import { setProductPage } from '../../Redux/actions/product.action';
import { GetCategoryProductsData } from '../../Services/networkCall';
import productState from '../../Redux/reducers/Products/product_page';

const ProductCard = ({ id, image, heading, description, view }) => {
  const navigate = useNavigate();

  const handleclick = async() => {
    console.log("view", view);
    // if (view === 2) {
    //   localStorage.setItem("productId", parseInt(id));
    //   store.dispatch(setProductPage(1))
    //   navigate(siteMap.ProductDetailPage.path, { replace: false });
    // } else {
    //   const products = await GetCategoryProductsData(id);
    //   if(products.result.length === 1){
    //     store.dispatch(setProductPage(1))
    //     navigate(siteMap.ProductDetailPage.path, { replace: false });
    //   }else{
    //     localStorage.setItem("productCategory", parseInt(id));
    //     store.dispatch(setProductPage((id)))
    //   }
    // }
    if(view === 1){
      console.log("i'm view 1");
      const products = await GetCategoryProductsData(id);
      console.log("product", products.code, id);
      if(products.code === 201){
        alert("NO PRODUCTS FOUND IN THIS CATEGORY");
      }else if(products.result.length === 1){
        navigate(siteMap.ProductDetailPage.path, {state:{fromCategory:true, result:products.result[0] || []}}, { replace: false });
      }else{
        localStorage.setItem("category_id", id)
        navigate(siteMap.ProductPage.path, {state:{id:id, result:products.result || []}}, { replace: false });
      }
    }else{
      console.log("id =>", id);
      localStorage.setItem("product_id", id)
      // const products = await GetCategoryProductsData(id);
      // console.log("FROM PRODUCT CARD", products);
      navigate(siteMap.ProductDetailPage.path, {state:{fromCategory:false, result:[]}}, { replace: false });
    }
  }

  return (
    <div className='ProductCard-main-container'>
      <div className="productcard-image-container">
        <img className="product-image" src={image} alt="" />
      </div>
      <div className="productcard-heading">
        {heading}
      </div>
      <div className="productcard-subheading">
        {description}
      </div>
      <div className="buttonStyle" onClick={() => handleclick()}>
        VIEW
      </div>
    </div>
  )
}
export default ProductCard