import "./Admin.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";

const Admin = () => {
  const [loggedIn, SetLoggedIn] = useState(false)


  const formik = useFormik({
      initialValues: {
        username: "",
        password: ""
      },
      validationSchema: Yup.object({
          username: Yup.string().required("Required"),
          password: Yup.string().required("Required")
      }),
      onSubmit: (values) => {
          if(values.username === "admin" && values.password === "Admin123"){
              SetLoggedIn(true)
          }else{
              alert("Please enter valid username / password")
          }
      },
    });


  return (
    <>
    {
      !loggedIn ? 
      <div className='Admin-auth-main-container'>
      <div className="Admin-auth-login-container">
          <div className='Auth_title'>ADMIN LOGIN</div>
          <div className='Auth_Form-container'>
          <form className="form-container-auth" onSubmit={formik.handleSubmit}>
          <div className="contact-form-input-warp-auth">
            <label className="label-auth" htmlFor="username">
            username
            </label>
            <input
              placeholder='UserName'
              className="form-input"
              id="username"
              name="username"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="contact-error">{formik.errors.username}</div>
            ) : null}
          </div>

          <div className="contact-form-input-warp-auth">
            <label className="label-auth" htmlFor="password">
            password
            </label>
            <input
            placeholder='Password'
              className="form-input"
              id="password"
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="contact-error">{formik.errors.password}</div>
            ) : null}
          </div>

          <button className="contact-submit" type="submit">
            LOGIN
          </button>
        </form>
          </div>
      </div>
  </div> 
  : <div className="Admin-container">
    <iframe width="90%" height="80%" src="https://app.tooljet.com/applications/6132e2d4-3998-4c48-9c45-86883b3386a1" title="ToolJet app - 6132e2d4-3998-4c48-9c45-86883b3386a1" frameborder="0" allowfullscreen></iframe>
     
  </div>
    }
    </>
  )
}
export default Admin


