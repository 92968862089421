import { Routes, Route } from "react-router-dom";
import { siteMap } from "./SiteMap";

import Home from "../Pages/Home/Home";
import Contact from "../Pages/Contact/Contact";
import Gallery from "../Pages/Gallery/Gallery";
import Certificate from "../Pages/Certificate/Certificate";
import About from "../Pages/About/About";
import Category from "../Pages/Categories/Category";
import Product from "../Pages/Products/Product"
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import TermsAndConditions from "../Pages/terms_and_conditions";
import Admin from "../Pages/Admin/Admin";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path={siteMap.HomePage.path} element={<Home />} />
      <Route path={siteMap.GalleryPage.path} element={<Gallery />} />
      <Route path={siteMap.CertificationPage.path} element={<Certificate />} />
      <Route path={siteMap.AboutPage.path} element={<About />} />
      <Route path={siteMap.ContactPage.path} element={<Contact />} />
      <Route path={siteMap.CategoryPage.path} element={<Category />} />
      <Route path={siteMap.ProductPage.path} element={<Product />} />
      <Route path={siteMap.ProductDetailPage.path} element={<ProductDetails />} />
      <Route path={siteMap.TermsAndConditionPage.path} element={<TermsAndConditions />} />
      <Route path={siteMap.AdminPage.path} element={<Admin />} />
    </Routes>
  );
};

export default PageRoutes;
