import './Product.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ProductCard from '../../Components/ProductCard/ProductCard'
// import aboutBanner from '../../Assets/productMBG.jpg'
// import aboutBanner from '../../Assets/CAROUSEL 1.jpg'
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { GetCategoryProductsData } from '../../Services/networkCall';



const Product = () => {
  const productCategory = localStorage.getItem("productCategory");
  const ProductSEO = useSelector(state => state.allseo)
  const ProductPage = useSelector(state => state.productState)
  const Category = useSelector(state => state.allcategory)
  // const Products = useSelector(state => state.all_products)
  const { state } = useLocation();
  const { result } = state;
  const [product, setProduct] = useState(result)


  


  useEffect(() => {
    console.log("result", result);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    if(result.length >= 1 ) return
    (async () => {
        const products = await GetCategoryProductsData(ProductPage);
        setProduct(products.result);
    })();
  }, [ProductPage])

  const product_images = product?.map((product) => {
    return (
        <ProductCard id={product.product_id} image={product.product_image} heading={product.product_name} description={product.product_description} view={product.view} />
    )
  })

  return (
    <div className='product-main-container'>
      <Helmet>
        <title>{ProductSEO === null ? "Product" : ProductSEO.result[1].title}</title>
        <meta name="description" content={ProductSEO === null ? "Product" : ProductSEO.result[1].description} />
        <meta name="keywords" content={ProductSEO === null ? "Product" : ProductSEO.result[1].keywords} />
      </Helmet>
      {/* <div className="about-banner-container">
        <img className="about-banner" src={aboutBanner} alt="about banner" />
      </div> */}
      <div className="product-title">
        {ProductPage === 0 ? "Our Products" : Category[parseInt(productCategory) - 1].category_name}
      </div>
      <div className="products-container">
        {product_images}
      </div>
    </div>
  )
}
export default Product