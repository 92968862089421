import { config } from "../Config/server.config";
import { store } from "..";
import { setAllProduct, setProductPage } from "../Redux/actions/product.action";
import { setAllCategory } from "../Redux/actions/category.action";
import { setAllGallery } from "../Redux/actions/gallery.action";
import { setAllSEO } from "../Redux/actions/seo.action";


// network call default options
const fetchOptions = (method, raw = null, referCode = null) => {
    // network call default header
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // (get method does not have body)
    if (method === "GET") {
        const requestOptions = {
            method: method,
            // headers: myHeaders,
            redirect: "follow",
            // credentials: "include",
        };
        return requestOptions;
    } else {
        const requestOptions = {
            method: method,
            // headers: myHeaders,
            body: raw,
            redirect: "follow",
            // credentials: "include",
        };
        return requestOptions;
    }
};

// To get the all the products using category id, call this function

export const GetCategoryProductsData = async (id) => {
    console.log(id, "id")
    const option = fetchOptions("POST");
    const url = `${config.server_url}/product/readCategory/?category_id=${id}`;
    console.log("url", url);
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                resolve(json_data) 
            });
    });
};



// To get specific products using product id, call this function

export const GetProductsData = async (product_id, category_id) => {
    console.log("from network", product_id, category_id);
    // var raw = JSON.stringify({
    //     "product_id": product_id,
    //     "product_category": category_id
    // });
    const option = fetchOptions("POST");
    const url = `${config.server_url}/product/read?product_id=${product_id}&product_category=${category_id}`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                // console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                // store.dispatch(setAllCategory(json_data));
                resolve(json_data);
            });
    });
};


// To get all Category, call this function

export const GetAllCategoryData = async () => {
    var raw = JSON.stringify({});
    const option = fetchOptions("POST", raw);
    const url = `${config.server_url}/category/readAll`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                console.log("from network", JSON.parse(data));
                localStorage.setItem("networkCategory", data)
                const json_data = JSON.parse(data);
                store.dispatch(setAllCategory(json_data));
                resolve(json_data);
            });
    });
};


// To get Gallery, call this function

export const GetGalleryData = async () => {
    var raw = JSON.stringify({});
    const option = fetchOptions("POST", raw);
    const url = `${config.server_url}/gallery/readAll`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                store.dispatch(setAllGallery(json_data));
                resolve(json_data);
            });
    });
};

// To get SEo DATA, call this function

export const GetSEOData = async () => {
    var raw = JSON.stringify({});
    const option = fetchOptions("POST", raw);
    const url = `${config.server_url}/seo/readAll`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                // console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                store.dispatch(setAllSEO(json_data));
                resolve(json_data);
            });
    });
};

export const SendMailWithImage = async (photo, name, phone, email, message) => {
    const formData = new FormData();
    formData.append('photos', photo);
    const option = fetchOptions("POST", formData);
    const url = `${config.server_url}/mail/sendWithImage?phone=${phone}&email=${email}&name=${name}&message=${message}`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                // console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                store.dispatch(setAllSEO(json_data));
                resolve(json_data);
            });
    });
};
export const SendMail = async (name, phone, email, message) => {
    var raw = JSON.stringify({});
    const option = fetchOptions("POST", raw);
    const url = `${config.server_url}/mail/send?phone=${phone}&email=${email}&name=${name}&message=${message}`;
    return new Promise((resolve, reject) => {
        fetch(url, option)
            .then((res) => res.text())
            .then((data) => {
                // console.log("from network", JSON.parse(data));
                const json_data = JSON.parse(data);
                store.dispatch(setAllSEO(json_data));
                resolve(json_data);
            });
    });
};