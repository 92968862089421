import "./carosel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import homeBanner1 from "../../Assets/CAROUSEL 3.jpg";
import homeBanner2 from "../../Assets/CAROUSEL 2.jpg";
import homeBanner3 from "../../Assets/CAROUSEL 1.jpg";


const CustomCarosel = () => {
  // const home = useSelector((state) => state.home)

  const home = {
    homeCarousel : [
      homeBanner1,
      homeBanner3,
      homeBanner2
    ]
  }
  return (
    <Carousel transitionTime={500} showIndicators={false} showArrows={false} emulateTouch={true} showThumbs={false} autoPlay={true} infiniteLoop={true} dynamicHeight={true} showStatus={false}>
      {
        home === null ? <div>Loading</div> : home.homeCarousel.map((image) => {
          return (
            <div className="carousel-image-controller">
              <img src={image} className="carosel-image" alt="" />
            </div>
          )
        })
      }
    </Carousel>
  );
};
export default CustomCarosel;
