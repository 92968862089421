import './App.css';
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import PageRoutes from "./Routes/Routes";
import Header from './Components/Header/header.component';
import Footer from './Components/Footer/footer.component';
import { useLocation } from "react-router-dom";
import { GetSEOData, GetAllCategoryData, GetGalleryData,GetCategoryProductsData } from './Services/networkCall';
import CustomizedDialogs from './Pages/dialog';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import whatsapp from "./Assets/whatsapp.png"


function App() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [ismobile, setIsmobile] = useState(false)
  const location = useLocation();

  useLayoutEffect(() => {
    if (window.screen.width <= 760 && location.pathname === "/") {
      setIsmobile(true)
      console.log("location", location.pathname)
      console.log("width", windowSize.current[0])
    } else {
      setIsmobile(false)
    }

  }, [location, windowSize])

  useEffect(() => {
    console.log("from App.js file");
    GetSEOData()
    GetAllCategoryData()
    GetGalleryData()
  }, [])

  //  useLayoutEffect(() => {
  //   console.log("width", windowSize.current[0])
  //  }, [])




  return (
    <div className='app-main-container'>
      <Header />
      <PageRoutes />
      {
        ismobile ? null : <Footer />
      }
      {/* <CustomizedDialogs /> */}
      <div style={{
        position : "fixed",
        bottom : ismobile ? "12%" : "15%",
        right : ismobile ? "6.5%" : "1.6%"
      }} onClick={()=>{window.open("https://wa.me/+919502952951")}}>
        <img src={whatsapp} style={{
                      color: "green",
                      width: 65,
                      height: 65,
                      cursor: "pointer",
                    }}></img>
      {/* <WhatsAppIcon
                    style={{
                      color: "green",
                      width: 70,
                      height: 70,
                      cursor: "pointer",
                    }}
                  /> */}
      </div>
    </div>
  );
}

export default App;
