import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';



const Type1 = ({typeOneDetail, productName}) => {
  // regex to check whether the cup is single wall
  const [orientation, setOrientation] = useState("vertical")

  const [isSingleWall, setIsSingleWall] = useState(false)

  useEffect(()=>{
    var regex = /^SW/;
    if(regex.test(productName)){
      setIsSingleWall(true)
    }else{
      setIsSingleWall(false)
    }

    if (window.screen.width <= 720) {
      setOrientation("horizontal");
      // setGap("3vh");
    }
  },[productName])

  return (
    <>
      <div className="Details-Container">
        <div className="Details-title">
          Specification
        </div>
        <div className="Product-Details">

          {
            !isSingleWall ? 
          Object.keys(typeOneDetail?.specifications || {}).map((key, value) =>
            <>
              <div className="detail-box-1">
                <div className="detail-box-title">
                  {key}
                </div>
                <div className="detail-box-value">
                  {typeOneDetail?.specifications[key]}
                </div>
              </div>
              {(value === Object.keys(typeOneDetail?.specifications).length - 1) ? (<div></div>) : (<Divider orientation={orientation} variant="middle" flexItem />)}
            </>
          ) : <>
          <div className="detail-box-1">
            <div className="detail-box-title">
              Cup
            </div>
            <div className="detail-box-value">
              {typeOneDetail?.specifications.Cup}
            </div>
          </div>
        </>
          }
        </div>
      </div>


      <div className="Details-Container">
        <div className="Details-title">
          Dimensions
        </div>
        <div className="Product-Details">
          {Object.keys(typeOneDetail?.dimensions || {}).map((key, value) =>
            <>
              <div className="detail-box-1">
                <div className="detail-box-title">
                  {key}
                </div>
                <div className="detail-box-value">
                  {typeOneDetail?.dimensions[key]}
                </div>
              </div>
              {(value === Object.keys(typeOneDetail?.dimensions).length - 1) ? (<div></div>) : (<Divider orientation={orientation} variant="middle" flexItem />)}
            </>
          )}
        </div>
      </div>


      <div className="Details-Container">
        <div className="Details-title">
          Package
        </div>
        <div className="Product-Details">
          {Object.keys(typeOneDetail?.package || {}).map((key, value) =>
            <>
              <div className="detail-box-1">
                <div className="detail-box-title">
                  {key}
                </div>
                <div className="detail-box-value">
                  {typeOneDetail?.package[key]}
                </div>
              </div>
              {(value === Object.keys(typeOneDetail?.package).length - 1) ? (<div></div>) : (<Divider orientation={orientation} variant="middle" flexItem />)}
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default Type1