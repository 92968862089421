import "./Gallery.css"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import React, { useState, useEffect } from 'react';
// import ProductZoom from "../../Components/MagicZoom/MagicZoom"
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import product1 from "../../Assets/Gallery/product 1.jpg"
import product2 from "../../Assets/Gallery/product 2.jpg"
import product3 from "../../Assets/Gallery/product 3.jpg"
import product4 from "../../Assets/Gallery/product 4.jpg"
import product5 from "../../Assets/Gallery/product 5.jpg"
import product6 from "../../Assets/Gallery/product 6.jpg"
import product7 from "../../Assets/Gallery/product 7.jpg"
import product8 from "../../Assets/Gallery/product 8.jpg"
import product9 from "../../Assets/Gallery/product 9.jpg"
// import product10 from "../../Assets/Gallery/product 10.jpg"
// import product11 from "../../Assets/Gallery/product 11.jpg"
// import product12 from "../../Assets/Gallery/product 12.jpg"




const Gallery = () => {
    const GallerySEO = useSelector(state => state.allseo)
    const Gallery = useSelector(state => state.allgallery)
    const [ismobile, setIsmobile] = useState(false)
    useEffect(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        if (window.screen.width <= 760) {
            setIsmobile(true)
        }
        console.log(Gallery,"gallery");
    }, [])


    const itemData = [
        {
            img: product1,
            title: 'product1',
        },
        {
            img: product2,
            title: 'product2',
        },
        {
            img: product3,
            title: 'product3',
        },
        {
            img: product4,
            title: 'product4',
        },
        {
            img: product5,
            title: 'product5',
        },
        {
            img: product6,
            title: 'product6',
        },
        {
            img: product7,
            title: 'product7',
        },
        {
            img: product8,
            title: 'product8',
        },
        {
            img: product9,
            title: 'product9',
        },
        // {
        //     img: product10,
        //     title: 'product10',
        // },
        // {
        //     img: product11,
        //     title: 'product11',
        // },
        // {
        //     img: product12,
        //     title: 'product12',
        // },
    ];

    return (
        <div className="gallery-main-container">
            <Helmet>
                <title>{GallerySEO === null ? "Gallery" : GallerySEO.result[3].title}</title>
                <meta name="description" content={GallerySEO === null ? "Gallery" : GallerySEO.result[3].description} />
                <meta name="keywords" content={GallerySEO === null ? "Gallery" : GallerySEO.result[3].keywords} />
            </Helmet>
            <div className="gallery-section-1-container">
                <div className="gallery-section-1-left">
                    Sustainability meets <br /> fashion.
                </div>
                <div className="gallery-section-1-right">
                    Made from paperboard that is both FSC-certified and Elemental Chlorine Free (ECF) compliant, our range of paper cups come in a variety of styles and sizes to meet all consumer needs, with different branding and customization options available.
                </div>
            </div>
            <div className="gallery-section-2-container">
                <Box sx={{ width: 1, height: '100%', overflowY: 'scroll', overflow: 'hidden' }}>
                    <ImageList variant="masonry" cols={!ismobile ? 3 : 1} gap={8} >
                        {Gallery?.map((item) => (
                            <ImageListItem key={item.image_url}>
                                <img
                                    src={`${item.image_url}?w=248&fit=crop&auto=format`}
                                    srcSet={`${item.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.image_name}
                                    loading="lazy"
                                    className="gallery-image"
                                />
                                {/* <ProductZoom productImage={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}/> */}
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </div>
        </div>
    )
}
export default Gallery