import './OurProduct.css'
import Glass1 from '../../Assets/glass1.png'
import Glass2 from '../../Assets/glass2.png'
import Glass3 from '../../Assets/SINGLE WALLED CUP.png'
// import Glass4 from '../../Assets/glass4.png'

const OurProduct = () => {
    return (
        <div className='OurProduct-main-container'>
            <div></div>
            <div className='OurProduct-Title'>
                Our Products
            </div>
            <div className='OurProduct-Description'>
                We manufacture paper cups in a range of dimensions. Our goods come in two types, “Single Wall Paper Cup” & “Double Wall Paper Cup” each in two colours, and are reasonably priced.
            </div>
            <div className="OurProduct-Image-Container">
            <div className="OurProduct-Image-Warpper">
                    <img className="OurProduct-Image" src={Glass3} alt="" />
                </div>
                
                <div className="OurProduct-Image-Warpper">
                    <img className="OurProduct-Image" src={Glass2} alt="" />
                </div>
                
                <div className="OurProduct-Image-Warpper">
                    <img className="OurProduct-Image" src={Glass1} alt="" />
                </div>
                {/* <div className="OurProduct-Image-Warpper">
                    <img className="OurProduct-Image" src={Glass3} alt="" />
                </div> */}
                
            </div>
        </div>
    )
}
export default OurProduct