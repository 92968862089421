import './Category.css'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ProductCard from '../../Components/ProductCard/ProductCard'
// import aboutBanner from '../../Assets/productMBG.jpg'
// import aboutBanner from '../../Assets/CAROUSEL 1.jpg'
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { GetCategoryProductsData } from '../../Services/networkCall';
import { GetAllCategoryData } from '../../Services/networkCall';


const Category = () => {
  const productCategory = localStorage.getItem("productCategory");
  const ProductSEO = useSelector(state => state.allseo)
  const ProductPage = useSelector(state => state.productState)
  const Category = useSelector(state => state.allcategory)
  // const Products = useSelector(state => state.all_products)
  const { state } = useLocation();
  const { id } = state;
  const [product, setProduct] = useState(null)


  const FindCategoryById = (categories, id) => {
    var result = [];
    for (var i = 0; i < categories.length; i++) {
      if (parseInt(categories[i].pre_category_id) === id) {
        result.push(categories[i]);
      }
    }
    return result
  }


  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    (async () => {
      const products = await GetAllCategoryData();
      console.log("category", products);
      const filteredCategory = FindCategoryById(products.result, id);
      setProduct(filteredCategory)
    })();
  }, [ProductPage, id])


  const product_images = product?.map((product) => {
    return (<ProductCard id={product.category_id} image={product.category_image} heading={product.category_name} description={product.category_description} view={product.view} />)
  })

  return (
    <div className='category-main-container'>
      <Helmet>
        <title>{ProductSEO === null ? "Product" : ProductSEO.result[1].title}</title>
        <meta name="description" content={ProductSEO === null ? "Product" : ProductSEO.result[1].description} />
        <meta name="keywords" content={ProductSEO === null ? "Product" : ProductSEO.result[1].keywords} />
      </Helmet>
      {/* <div className="about-banner-container">
        <img className="about-banner" src={aboutBanner} alt="about banner" />
      </div> */}
      <div className="category-title">
        {ProductPage === 0 ? "Our Products" : Category[parseInt(productCategory) - 1].category_name}
      </div>
      <div className="category-container">
        {product_images}
      </div>
    </div>
  )
}
export default Category